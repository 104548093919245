<template>
    <div
      class="timer-container"
      @mousedown="startDrag"
      ref="timer"
    >
      <!-- SVG Timer -->
      <div class="svg-timer">
        <svg width="200" height="200">
          <circle
            cx="100"
            cy="100"
            r="90"
            :stroke-dasharray="circumference"
            :stroke-dashoffset="offset"
            stroke-width="10"
            stroke="#3f51b5"
            fill="none"
            class="progress-circle"
          />
        </svg>
        <div class="time-display">{{ minutes }}:{{ seconds }}</div>
      </div>
    
      <!-- Controls -->
      <div class="controls">
        <button @click="startTimer">Start</button>
        <button @click="pauseTimer">Pause</button>
        <button @click="resetTimer">Reset</button>
        <button @click="closeTimer">Close</button>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, computed, onMounted, onUnmounted } from 'vue';
  import emitter from '../eventbus';
  
  export default {
    props: {
      initialMinutes: {
        type: Number,
        default: 5 // 默认时间为5分钟
      },
      autoStart: {
        type: Boolean,
        default: false // 默认情况下不自动开始
      }
    },
    setup(props) {
      const totalTime = ref(props.initialMinutes * 60); // 使用传入的初始分钟数转换为秒
      const remainingTime = ref(totalTime.value);
      const interval = ref(null);
  
      const circumference = 2 * Math.PI * 90; // 圆的周长
      const offset = computed(() => circumference - (circumference * remainingTime.value) / totalTime.value);
  
      const minutes = computed(() => Math.floor(remainingTime.value / 60).toString().padStart(2, '0'));
      const seconds = computed(() => (remainingTime.value % 60).toString().padStart(2, '0'));
  
      const startTimer = () => {
        if (interval.value) return;
        interval.value = setInterval(() => {
          if (remainingTime.value > 0) {
            remainingTime.value--;
          } else {
            clearInterval(interval.value);
            interval.value = null;
          }
        }, 1000);
      };
  
      const closeTimer = () => {
        emitter.emit("closetimer", 1);
      };
  
      const pauseTimer = () => {
        clearInterval(interval.value);
        interval.value = null;
      };
  
      const resetTimer = () => {
        clearInterval(interval.value);
        remainingTime.value = totalTime.value;
        interval.value = null;
      };
  
      // 拖动功能
      const timer = ref(null);
      const startDrag = (event) => {
        const timerElement = timer.value;
        let shiftX = event.clientX - timerElement.getBoundingClientRect().left;
        let shiftY = event.clientY - timerElement.getBoundingClientRect().top;
  
        const onMouseMove = (event) => {
          timerElement.style.left = event.clientX - shiftX + 'px';
          timerElement.style.top = event.clientY - shiftY + 'px';
        };
  
        document.addEventListener('mousemove', onMouseMove);
  
        document.onmouseup = () => {
          document.removeEventListener('mousemove', onMouseMove);
          document.onmouseup = null;
        };
      };
  
      onMounted(() => {
        // 检查 autoStart 是否为 true，如果是，自动启动计时器
        if (props.autoStart) {
          startTimer();
        }
      });
  
      onUnmounted(() => {
        clearInterval(interval.value);
      });
  
      return {
        minutes,
        seconds,
        startTimer,
        closeTimer,
        pauseTimer,
        resetTimer,
        circumference,
        offset,
        startDrag,
        timer,
      };
    },
  };
  </script>
  
  <style scoped>
  .timer-container {
    text-align: center;
    padding: 20px;
    font-family: Arial, sans-serif;
    position: absolute;
    top: 100px;
    left: 100px;
    cursor: move;
    user-select: none;
    z-index: 9999;
    background-color: white; /* 确保背景色为不透明的白色 */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* 添加阴影 */
  }
  
  .svg-timer {
    margin-bottom: 20px;
    position: relative;
  }
  
  .progress-circle {
    transition: stroke-dashoffset 1s linear;
  }
  
  .time-display {
    font-size: 24px;
    color: #3f51b5;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .controls {
    margin-top: 20px;
  }
  
  .controls button {
    margin: 5px;
    padding: 10px 20px;
    background-color: #3f51b5;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    pointer-events: auto; /* 按钮可以捕捉点击事件 */
  }
  
  .controls button:hover {
    background-color: #303f9f;
  }
  </style>
  