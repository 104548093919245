<template>
 <svg width="26" height="26" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
  <title>{{ title }}</title>
<path d="M32 10.5C26.3003 10.5079 20.8363 12.7757 16.806 16.806C12.7757 20.8363 10.5079 26.3003 10.5 32C11.68 60.52 52.32 60.51 53.5 32C53.4921 26.3003 51.2243 20.8363 47.194 16.806C43.1637 12.7757 37.6997 10.5079 32 10.5V10.5ZM32 50.5C27.0951 50.4947 22.3926 48.5439 18.9244 45.0756C15.4561 41.6074 13.5053 36.9049 13.5 32C14.5 7.46 49.5 7.46 50.5 32C50.4947 36.9049 48.5439 41.6074 45.0756 45.0756C41.6074 48.5439 36.9049 50.4947 32 50.5V50.5Z" fill="url(#paint0_linear_122:2532)"/>
<path d="M32.0001 18.7C30.0383 18.7026 28.1576 19.4831 26.7704 20.8703C25.3832 22.2575 24.6027 24.1382 24.6001 26.1C24.6001 26.4978 24.7581 26.8793 25.0394 27.1606C25.3207 27.4419 25.7023 27.6 26.1001 27.6C26.4979 27.6 26.8795 27.4419 27.1608 27.1606C27.4421 26.8793 27.6001 26.4978 27.6001 26.1C27.6001 25.2297 27.8582 24.379 28.3416 23.6554C28.8251 22.9319 29.5123 22.3679 30.3163 22.0349C31.1203 21.7019 32.005 21.6147 32.8585 21.7845C33.712 21.9543 34.496 22.3733 35.1114 22.9887C35.7267 23.604 36.1458 24.388 36.3156 25.2416C36.4853 26.0951 36.3982 26.9798 36.0652 27.7838C35.7321 28.5878 35.1682 29.2749 34.4446 29.7584C33.721 30.2419 32.8703 30.5 32.0001 30.5C31.6023 30.5 31.2207 30.658 30.9394 30.9393C30.6581 31.2206 30.5001 31.6021 30.5001 32V36.25C30.5001 36.6478 30.6581 37.0293 30.9394 37.3106C31.2207 37.5919 31.6023 37.75 32.0001 37.75C32.3979 37.75 32.7795 37.5919 33.0608 37.3106C33.3421 37.0293 33.5001 36.6478 33.5001 36.25V33.3499C42.1001 31.4099 40.8901 18.89 32.0001 18.7Z" fill="url(#paint1_linear_122:2532)"/>
<path d="M30.9402 41.9399C30.6752 42.2243 30.531 42.6004 30.5378 42.989C30.5447 43.3776 30.7021 43.7484 30.9769 44.0232C31.2518 44.298 31.6225 44.4554 32.0111 44.4623C32.3997 44.4691 32.7758 44.3249 33.0602 44.0599C33.3251 43.7756 33.4694 43.3995 33.4625 43.0109C33.4557 42.6223 33.2983 42.2515 33.0234 41.9767C32.7486 41.7019 32.3778 41.5444 31.9892 41.5376C31.6006 41.5307 31.2245 41.675 30.9402 41.9399V41.9399Z" fill="url(#paint2_linear_122:2532)"/>
<defs>
<linearGradient id="paint0_linear_122:2532" x1="10.5" y1="31.94" x2="53.5" y2="31.94" gradientUnits="userSpaceOnUse">
<stop stop-color="#6031A5"/>
<stop offset="0.63" stop-color="#412083"/>
<stop offset="1" stop-color="#331974"/>
</linearGradient>
<linearGradient id="paint1_linear_122:2532" x1="387.401" y1="556.1" x2="604.934" y2="556.1" gradientUnits="userSpaceOnUse">
<stop stop-color="#6031A5"/>
<stop offset="0.63" stop-color="#412083"/>
<stop offset="1" stop-color="#331974"/>
</linearGradient>
<linearGradient id="paint2_linear_122:2532" x1="119.726" y1="167.349" x2="128.56" y2="167.349" gradientUnits="userSpaceOnUse">
<stop stop-color="#6031A5"/>
<stop offset="0.63" stop-color="#412083"/>
<stop offset="1" stop-color="#331974"/>
</linearGradient>
</defs>
</svg>
  </template>
<script>
  export default {
    name: "manualicon",

  props: ['title'],
  };
  </script>
      
  <style scoped>
  </style>