<template>
  <div class="area_addprject_right">
        <a>创建目标<span class="golanameinaddzone">{{goal.goaltext}}</span>的子工程</a>
       <!-- <input  v-model:value="projecttext_input"  style="display:block;width:100%;height:30px"> -->
       <a-input style="display:block;width:100%;height:30px" v-model:value="projecttext_input" placeholder="请输入项目名" />
       <button @click="createprojectingoalfunc(goal)"   class="addprojectbutton"    style="right:0px">提交</button>
       </div>
</template>

<script>
import { defineComponent,ref } from 'vue';
import emitter from "../eventbus.js"; 
const axios = require('axios').default;
import configure_options from '../configure'
export default  defineComponent({
  name: 'createprojectingoal',
  props: {
    msg: String,
    goal: {
      type: Object,
      required: true
    },
  },
  setup(props,context) {
//   const value1 = ref(500);
console.log(props)
console.log(context)
   function createprojectingoalfunc(goal){
    //    alert("请输入你的工程")
        // alert(this.projecttext_input)
        // alert(goal.goalcode)
    var env =  configure_options["env"]
       
        console.log(configure_options["app_setting"][env])
        var serverhost =configure_options["app_setting"][env]["serverhost"]
    if(this.projecttext_input==""){
      alert("请输入项目名，项目名不能为空")
      return 
    }

     var project ={"goalcode":goal.goalcode,"projectname":this.projecttext_input}
    axios.post(serverhost+'/v1/createproject', project)
    .then(response => {
        console.log(response.data)
     
        //可以将状态传入到父亲组件,应该是这里除了问题
       document.getElementById("createprojectzone").style.display="none"
       document.getElementById("goaltreedatapart").style.opacity=1
       document.getElementById("goaltreedatapart").style.pointerEvents="auto"
       document.getElementById("top").style.opacity=1
       emitter.emit("turnoffshowaddprojectbox","createproject_component_ask")
       emitter.emit("refresh_goaltree","createproject_component_ask")
    })
    .catch(error=>{
      
      console.log(error)
          // alert(response.status)
        // console.log(response.status)
        //
        if(error.response.status=="802"){
          alert("该项目已经创建，请使用别的项目名")
          return
        }
    
    })
      


   }

    return {
      projecttext_input:ref(""),
      createprojectingoalfunc
    };
  },
  // mounted(){
  //   emitter.on("trunoffshowaddprojectbox",msg=>{
  //       //  alert(msg)
  //       // console.log("HomeContent接收到得About发送得数据了：",msg);
  //       if(msg=="createproject_component_ask"){
  //       //   this.getdata("today")
  //       //   alert("检测事件是否被激发")
  //         this.get_goaltree()
  //       }
  // })}
})

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#back{
  position: absolute;;
  left: 10%;
}

.golanameinaddzone{
  color: red;
}

.addprojectbutton { 

  border-radius:10px;
  color:white;
  font-weight: 900;
  border:solid 1px;
  background: #1a73e8;
  max-width:100px;
  &:hover{
    background: #4479be;
  }
}



</style>
