<template>
    <!-- <?xml version="1.0" encoding="iso-8859-1"?> -->
    <!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <!-- <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"> -->
    <svg fill="#000000" width="24px" height="24px" viewBox="0 0 34 34" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <path d="M31.908 26.249l-5.852-10.822c0.597-1.355 0.931-2.852 0.931-4.428 0-6.072-4.922-10.994-10.994-10.994-6.073 0-10.995 4.923-10.995 10.994 0 1.614 0.351 3.145 0.974 4.524l-5.878 10.721c-0.19 0.345-0.158 0.77 0.079 1.084 0.237 0.314 0.638 0.461 1.022 0.371l5.019-1.151 1.718 4.785c0.134 0.372 0.474 0.63 0.867 0.659 0.025 0.002 0.050 0.003 0.074 0.003 0.366 0 0.706-0.201 0.881-0.527l5.116-9.53c0.369 0.038 0.744 0.057 1.123 0.057 0.347 0 0.69-0.018 1.029-0.050l5.227 9.532c0.177 0.323 0.514 0.52 0.877 0.52 0.026 0 0.052-0.001 0.078-0.003 0.392-0.032 0.73-0.289 0.863-0.659l1.718-4.785 5.020 1.151c0.385 0.093 0.782-0.057 1.020-0.369s0.27-0.735 0.084-1.081zM9.056 28.542l-1.258-3.505c-0.172-0.477-0.671-0.754-1.165-0.637l-3.712 0.852 4.231-7.718c1.393 1.883 3.373 3.303 5.67 3.994zM7.007 10.999c0-4.955 4.032-8.986 8.986-8.986s8.985 4.031 8.985 8.986-4.031 8.986-8.986 8.986c-4.955 0-8.986-4.032-8.986-8.986zM25.367 24.4c-0.496-0.117-0.993 0.16-1.165 0.636l-1.267 3.53-3.849-7.017c2.357-0.691 4.386-2.148 5.797-4.085l4.214 7.791z"></path>
</svg>  </template>
      <script>
  export default {
    name: "badgeicon",
  };
  </script>
      
      <style scoped>
  </style>