<template>
<div>
  <mapofgoal v-show="showmapofgoal"><a href="/mapofgoal">💙
</a></mapofgoal>
<div id="goalrecursiontree">
  <a-spin v-if="showloading"    tip="Loading...">
    <a-alert
     
      description="goals graphs is Loading....."
    ></a-alert>
  </a-spin>
  <div>
        <!-- <Treeview :node="treeData" /> -->
    <div id="createprojectzone" v-if="showaddprojectzone">
    <button @click="turnoff_addprojectzone_func" style="float:right;background:none;border:none"><closebutton/></button>
    <createprojectingoal :goal="passedgoal"></createprojectingoal>
   </div>
   
   <div id="addtaskinprojectzone" v-if="showaddtaskbox">
    <a>增加项目<span class="projectnameinaddzone">{{selectedproject.Name}}</span>的任务</a>
    <button @click="turnoff_addtaskinprojectzone_func" style="border:none;background: none;"><closebutton/></button>
    <!-- <closebutton @click="turnoff_addtaskinprojectzone_func"></closebutton> -->
    <taskbox :goal="selectedgoal" :project="selectedproject"></taskbox>
   </div>
   
   
   <div id="addtaskinprojectzone" v-if="showedittaskbox">
    <!-- <closebutton @click="turnoff_addtaskinprojectzone_func"></closebutton> -->
    <taskedit  class="taskedit_zone"   @testchild="test"      v-on:turnoffchildbox = 'turnoffchildboxfunc'    v-show="showedittaskbox"   :parenttask="currenttask"  :action='action'> </taskedit>
   </div>



   <div id="goaltreedatapart">
   
    <div class="goal" v-for="goal in goaltreedata.goals" v-bind:key="goal.Name">
      
       <div class="goaltitlezone">
     
        <a v-if="showgoalalias&goal.GoalAlias!='unspecified'"   class="goaltext" :id="'goaltext_' + goal.Goalcode"> {{ goal.GoalAlias }}</a>
        <a v-else  class="goaltext" :id="'goaltext_' + goal.Goalcode"> {{ goal.Name }}</a>
        <!-- <button class="togglebutton"  @click="toggle(goal, 'goal' ,$event)">-</button> -->
       
       <div class="buttoningoal">
       <div   v-show='showunfoldmap[goal.Goalcode].showcherry'  @click="toggle(goal,'goal',$event,'fold')">
          <Cherryicon  ></Cherryicon>
        </div>
        <div   v-if="showunfoldmap[goal.Goalcode].showapple" @click="toggle(goal,'goal',$event,'unfold')">
          <Appleicon></Appleicon>
        </div>
        <div>
        <plusbutton  info="增加当前目标的项目" @click="createproject(goal, 'goal' ,$event)"></plusbutton>
        </div>
      </div>
       </div>
     
    
       <div :id="'block_' + goal.Goalcode"  v-if="goal.Allprojectsingoal.length>0">
          <!-- 这里的区域是悬浮在页面上的 -->
       

        <div class="project" v-for="project in goal.Allprojectsingoal" v-bind:key="project.Name">
        <div class="projectshowzone_right">
        <a class="projecttext"   
        v-on:dragover="allowDrop($event)" 
        @dragenter="dragenterfunc($event,'project')" 
        @dragleave="dragleavefunc($event)"  
        v-on:drop="dropinproject(project.Projectcode,$event)"
        :projectid="project.Projectcode "
         :id="'projecttext_'  + project.Projectcode ">{{ project.Name }}</a>
        <div class="buttonzone_in_project">
        <!-- <button class="togglebutton"  @click="toggle(project,'project',$event)">-</button> -->
        <div   v-show='showunfoldmap[project.Projectcode].showcherry'  @click="toggle(project,'project',$event,'fold')">
          <Cherryicon  ></Cherryicon>
        </div>
        <div   v-if="showunfoldmap[project.Projectcode].showapple" @click="toggle(project,'project',$event,'unfold')">
          <Appleicon></Appleicon>
        </div>
      

        <!-- <div  showall="yes"   @click="toggle(project,'project',$event)">
          <Todayword ></Todayword>
        </div> -->
        <!-- <button class="addtaskbutton"  @click="createtaskinproject(goal, project ,$event)">add task</button> -->
        <div>
        <Plusbutton  info="在当前项目增加任务"  @click="createtaskinproject(goal, project ,$event)" ></Plusbutton>
        </div>
        <div>
          <Giveupword  @click="giveupproject(goal,project,$event)"></Giveupword>
        </div>
        <div> 
          <donebutton @click="finishproject(goal,project,$event)"></donebutton>
        </div>
        
        </div>
        </div>
        <!-- <button class="addtaskbutton"  @click="createtaskinproject(goal, project ,$event)">finish project</button> -->

        <!-- 可以在这里进行关闭测试 -->
        <div :id="'block_' + project.Projectcode"   v-if="project.Alltasksinproject!=undefined&project.Alltasksinproject!=null">
        
          <div class="task" v-for="task in project.Alltasksinproject" v-bind:key="task.Name">
        <div :id="'block_' + task.ID" class="tasktext_block">
         
            <a :class= task.tagsarray 
            
            :draggable="dragabledata"
        v-on:dragstart="drag(task.ID,$event)"  
        
            :id="'tasktext_' + task.ID" @click="edittask(task)"   > {{ task.task }}</a>
            <!-- <button class="todaybutton" @click="schedule2today(task)"  >今</button> -->
          
         
          <div class="buttonzone_in_task">
            <Todayword  class="todaytask"  @click="schedule2today(task)"></Todayword>
          <Tomorrowword  class="tomorrowtask"  @click="schedule2tomorrow(task)"></Tomorrowword>
          <!-- <button class="giveupbutton" @click="giveup(task)"  >弃</button> -->
        <Giveupword class="giveuptask" @click="giveup(task)"></Giveupword>
          </div>
  
        </div>
        </div>
       
        </div>
        </div>
        </div>
        
    </div>
    
     
    </div>
  </div>


</div>
</div>
</template>

<script>
// import configure_options from '../configure'
import emitter from "../eventbus.js"; 
import taskbox from "./taskbox.vue"
import createprojectingoal from "./createprojectingoal.vue"
import { defineComponent,ref } from 'vue';
import configure_options from '../configure'
import Tomorrowword from '../svg/tomorrowword.vue'
import Todayword from '../svg/todayword.vue'
import Cherryicon from '../svg/cherryicon.vue'
import Appleicon from '../svg/appleicon.vue'
import Giveupword from '../svg/giveupword.vue'
import Plusbutton from  '../svg/plusbutton.vue'
import donebutton from  '../svg/done.vue'
import closebutton from  '../svg/close.vue'
import taskedit from "./taskedit.vue"
import { notification } from 'ant-design-vue';


const axios = require('axios').default;
// axios.defaults.withCredentials = true
export default  defineComponent(
    {
  name: 'Goaltreerecursion',
  components:{
      createprojectingoal,
      taskbox,
      taskedit,
      Tomorrowword,
      Giveupword,
      Todayword,
      donebutton,
      closebutton,
      Plusbutton,
      Appleicon,
      Cherryicon,
      
},
  props: {
    msg: String,
    // passedgoal:{
    //     type: Object,
    //   required: true
    // }
  },

  methods:{
    onDragEnd(event) {
      console.log('拖动结束:', event);
    },

    edittask(task){
     
      // alert("task get clicked")
      // console.log(goal,project,event)
        // alert("在项目中添加任务")
        
      this.currenttask = task
      console.log("----------注意当前任务---------------------")
      console.log(this.currenttask)
      //   document.getElementById("createprojectzone").style.opacity=0.1
        document.getElementById("goaltreedatapart").style.opacity=0.1
        document.getElementById("goaltreedatapart").style.pointerEvents="none"
        document.getElementById("top").style.opacity=0.1
      //   this.passedgoal={"goalcode":goal.Goalcode,"goaltext":goal.Name}
        this.action = "edittaskfromgoaltree"
        this.showedittaskbox= true
        // this.selectedgoal = goal
        // this.selectedproject = project
     

    },

  
  },



    mounted(){
    // this.showloading = true;
      // var  options=[...Array(25)].map((_, i) => ({
      //   value: (i + 10).toString(36) + (i + 1)+"人",
      // }))
      // console.log(options)
 // 监听区域

 emitter.on("createtaskINproject",msg=>{
  console.log("收到creattask in project🌲更新消息");
  console.log(msg)
// 直接将其加到对应的任务树当中来
this.showaddtaskbox = false;
this.addTaskInProjectEmi(msg)
 })




         emitter.on("refresh_goaltree",msg=>{
        //  alert(msg)
        console.log("收到goaltree🌲更新消息");
        console.log()
        if(msg=="createproject_component_ask"){
        //   this.getdata("today")
        //   alert("检测事件是否被激发")
          this.get_goaltree()
       
        }


        if(msg=="from_taskedit_component"){
        //   this.getdata("today")
          // alert("检测事件是否被激发")
          this.showedittaskbox = false;
          this.get_goaltree()
          
          document.getElementById("goaltreedatapart").style.opacity=1
          document.getElementById("goaltreedatapart").style.pointerEvents="auto"
          document.getElementById("top").style.opacity=1
        }

           if( msg =="createtaskinproject_component_ask"){
            this.showaddtaskbox = false;
            this.get_goaltree()
           }

           
           if( msg =="taskrecusiontree_component_ask"){
            this.showaddtaskbox = false;
            this.get_goaltree()
           }
         
        
        });
    //            turnoffshowaddprojectbox
      emitter.on("turnoffshowaddprojectbox",msg=>{
        //  alert(msg)
        // console.log("HomeContent接收到得About发送得数据了：",msg);
        if(msg=="createproject_component_ask"){
        //   this.getdata("today")
        //   alert("检测事件是否被激发")
          // alert(1)
          this.showaddprojectzone=false
        }
  })
        


     emitter.on("trunofftaskedit",msg=>{
      // alert("检查是否被盗用")
        // alert
        if(msg=="from_taskedit_component"){
          this.showedittaskbox = false;
      document.getElementById("goaltreedatapart").style.opacity=1
      document.getElementById("goaltreedatapart").style.pointerEvents="auto"
      document.getElementById("top").style.opacity=1
        }
      })


      this.get_goaltree()
  
     }
    ,setup(props,context){

    

  // deal with drage event
  function drag(taskid,event) {
  //   console.log("拖动的元素model is") 
  //   console.log(model)
  //   console.log(typeof model.tasktags)
  //   var tasktags = model.tasktags
  //   var obj = JSON.parse(tasktags)
  //   console.log(obj)


  //   const keysWithValueYes = Object.keys(obj).filter(key => obj[key] === "yes");
  // console.log(keysWithValueYes);
  console.log("🌐🌐🌐🌐")  
  console.log(taskid)

     event.dataTransfer.setData("origintask", taskid);
    }

    function allowDrop(ev) {
      console.log("allowdrop被调用过")
    ev.preventDefault();
    // console.log(overelement)
    // if(overelement!=null){

    //   overelement.backgroundColor= 'green';
    // }
    // ev.target.style.backgroundColor = "red"
    // overelement = ev.target
    }


    function  drop(model,ev) {
        ev.preventDefault();
        var origintask = JSON.parse(ev.dataTransfer.getData("origintask"));
        
        console.log(`获取到的数据为${origintask.ID}`)
        console.log(origintask)
        console.log(`放入的目标task id ${model.ID}`)
        var origintaskid =  origintask.ID
        var targetid = model.ID
        // ev.target.appendChild(document.getElementById(data));
        // 这里尝试更新数据的关系在这里
        var env =  configure_options["env"]
       
       console.log(configure_options["app_setting"][env])
       var serverhost =configure_options["app_setting"][env]["serverhost"]
       var updatedtask_info  = {
        'ifdissect': 'no',
        'deadline': 'unspecified',
        'starttime': 'unspecified',
        'endtime': 'unspecified',
        'tasktagsorigin': 'unspecified',
        'parentid': targetid,
        'timedevotedto_a_task': "unspecified",
        'goal': "unspecified",
        'client': 'dreamforlifeweb',
        'taglight': 'no',
        'note': 'unspecified',
        'reviewalgolight': 'yes',
        'reviewalgo': 'unspecified',
        'parentproject': 'unspecified',
        'id': origintaskid,
        'place': 'unspecified',
        'finishtime': 'unspecified',
        'inbox': "nocontent",
        'project':  "unspecified",
        'plantime': 'unspecified',
        'taskstatus': 'unfinished',
        'tasktags': {}
        }
        axios.post(serverhost+  '/v1/updateprojectcode', updatedtask_info)
       .then(response => {
      this.taskchecked = false
      console.log(response.data)
      // alert("提交任务陈工")
      // context.emit('refresh',"today")
        //可以将状态传入到父亲组件
        ev.target.style.backgroundColor="transparent"
        emitter.emit("refreshtasks","today")
        // emitter.emit("refresh_review","from_taskrecursion_component")
        // alert("检测左端出发")
    })
    .catch(error=>{console.log(error)})






      }


  // 更新任务的项目代码和目标代码
  function  updateTaskProject(origintaskid, targetprojectid) {
    // 查找并更新任务的项目和目标代码
    for (let goal of this.goaltreedata.goals) {
      for (let project of goal.Allprojectsingoal) {
        // 确保 Alltasksinproject 是一个数组
        if (!Array.isArray(project.Alltasksinproject)) {
          project.Alltasksinproject = []; // 初始化为一个空数组
        }

        let taskIndex = project.Alltasksinproject.findIndex(task => task.ID == origintaskid);
        if (taskIndex !== -1) {
          // 找到任务，将其移除并添加到目标项目
          let task = project.Alltasksinproject.splice(taskIndex, 1)[0];
          let targetProject = goal.Allprojectsingoal.find(p => p.Projectcode === targetprojectid);

          if (targetProject) {
            // 确保目标项目的 Alltasksinproject 是一个数组
            if (!Array.isArray(targetProject.Alltasksinproject)) {
              targetProject.Alltasksinproject = []; // 初始化为一个空数组
            }

            task.Projectcode = targetProject.Projectcode;
            task.Goalcode = goal.Goalcode; // 更新目标代码，如果需要
            targetProject.Alltasksinproject.push(task);
          }

          return; // 一旦找到并更新任务，退出循环
        }
      }
    }
  }



// 收到emitter的信号后，来更新数据
  function addTaskInProjectEmi(task) {
  // 从传入的任务对象中提取目标的 Goalcode 和目标项目的 Projectcode
  const targetGoalCode = task.goalcode;
  const targetProjectCode = task.projectcode;

  // 查找与任务 Goalcode 匹配的目标
  let targetGoal = this.goaltreedata.goals.find(goal => goal.Goalcode === targetGoalCode);

  if (targetGoal) {
    // 如果找到了目标，查找与任务 Projectcode 匹配的项目
    let targetProject = targetGoal.Allprojectsingoal.find(project => project.Projectcode === targetProjectCode);

    if (targetProject) {
      // 确保目标项目的 Alltasksinproject 是一个数组
      if (!Array.isArray(targetProject.Alltasksinproject)) {
        targetProject.Alltasksinproject = []; // 初始化为一个空数组
      }

      // 将任务添加到目标项目的 Alltasksinproject 数组中
      targetProject.Alltasksinproject.push(task);

      return; // 一旦找到并添加任务，退出函数
    } else {
      console.warn(`没有找到ID为 ${targetProjectCode} 的目标项目。任务未添加。`);
    }
  } else {
    console.warn(`没有找到 Goalcode 为 ${targetGoalCode} 的目标。任务未添加。`);
  }
}


 function dropinproject(targetprojectid,ev){
  ev.preventDefault();
  var origintaskid =ev.dataTransfer.getData("origintask");
  console.log("🔋🔋🔋")
  console.log(origintaskid)
  console.log(targetprojectid)

  var env =  configure_options["env"]
       
       console.log(configure_options["app_setting"][env])
       var serverhost =configure_options["app_setting"][env]["serverhost"]
       var updatedtask_info  = {
     
        'tagrgetprojectid': targetprojectid,

        'taskid': origintaskid,
     
        }
        axios.post(serverhost+  '/v1/updatetaskProjectcode', updatedtask_info)
       .then(response => {
      this.taskchecked = false
      console.log(response.data)
      // alert("提交任务陈工")
      // context.emit('refresh',"today")
        //可以将状态传入到父亲组件
        this.updateTaskProject(origintaskid, targetprojectid);
        ev.target.style.backgroundColor="transparent"
        
        // emitter.emit("refreshtasks","today")
        // emitter.emit("refresh_review","from_taskrecursion_component")
        // alert("检测左端出发")
    })
    .catch(error=>{console.log(error)

      alert("目前不允许移动到该位置")
    })



 }
     function dragenterfunc(e,typeofitem) {
      // console.log(val)
      // this.newDataItem = val
      e.preventDefault()
      if(typeofitem=="project"){
        e.target.style.backgroundColor = "yellow"
      }else{
 e.target.style.backgroundColor = "red"
      }
     
      }


      function dragleavefunc(e) {
      // console.log(val)
      // this.newDataItem = val
      console.log("离开函数")
      console.log(e.target)
      e.preventDefault()
      e.target.style.backgroundColor = "transparent"
    //  
   


      }

   


      console.log(props,context)


   function turnoff_addtaskinprojectzone_func(){
    this.showaddtaskbox = false;
    // document.getElementById("createprojectzone").style.opacity=1
      document.getElementById("goaltreedatapart").style.opacity=1
       document.getElementById("goaltreedatapart").style.pointerEvents="auto"
      document.getElementById("top").style.opacity=1
   }


   function turnoff_addprojectzone_func(){
    this.showaddprojectzone = false;
    // document.getElementById("createprojectzone").style.opacity=1
      document.getElementById("goaltreedatapart").style.opacity=1
       document.getElementById("goaltreedatapart").style.pointerEvents="auto"
      document.getElementById("top").style.opacity=1
   }   
    function  giveup(task){
    // alert(task.task)
   const  scheduleids = [task.ID]
   const postbody = {"giveuptaskids":scheduleids}
   var env =  configure_options["env"]
       
   console.log(configure_options["app_setting"][env])
   var serverhost =configure_options["app_setting"][env]["serverhost"]
   axios.post(serverhost +'/v1/giveuptasksbatch', postbody)
    .then(response => {
        console.log(response.data)
        // emitter.emit("refreshtasks","today")
        // emitter.emit("refresh_review","from_taskrecursion_component")
        var blockstring = "block_"+task.ID
            document.getElementById(blockstring).remove()


    })
    .catch(error=>{console.log(error)})




    }

    



      function schedule2today(task){
        //alert(task.ID)
        // alert("获取明天的消息")
        // alert(task.task)
        const scheduleids = [task.ID]
        const postbody = {"todaytaskids":scheduleids}
          var env =  configure_options["env"]
       
        console.log(configure_options["app_setting"][env])
        var serverhost =configure_options["app_setting"][env]["serverhost"]


        axios.post(serverhost +  '/v1/todaytasksbatch', postbody)
        .then(response => {
            console.log(response.data)
            emitter.emit("refreshtasks","today")
            var blockstring = "block_"+task.ID
            document.getElementById(blockstring).remove()
        })
        .catch(error=>{
            console.log(error)
        })}
    
      function schedule2tomorrow(task){
        //alert(task.ID)
        // alert("获取明天的消息")
        // alert(task.task)
        const scheduleids = [task.ID]
        const postbody = {"tomorrowtaskids":scheduleids}
          var env =  configure_options["env"]
       
        console.log(configure_options["app_setting"][env])
        var serverhost =configure_options["app_setting"][env]["serverhost"]

        axios.post( serverhost+  '/v1/tomorrowtasksbatch', postbody)
        .then(response => {
            console.log(response.data)
            emitter.emit("refreshtasks","tomorrow")
            var blockstring = "block_"+task.ID
            document.getElementById(blockstring).remove()
        })
        .catch(error=>{
            console.log(error)
        })}
     function toggle(model,texttype,event,action){
        //  alert(project.Projectcode)
      
        
        // var thisele = event.target
        // var toggletext = event.target.innerHTML
        var thisshowall = event.target.getAttribute("showall"); 
        console.log(`dan'b'g ${thisshowall}`)
        if (action=="fold"){
            if (texttype=="project"){
                document.getElementById(`block_${model.Projectcode}`).style.display = "none";
                this.showunfoldmap[model.Projectcode].showcherry=false
                this.showunfoldmap[model.Projectcode].showapple=true
            }
         if (texttype=="goal"){
                document.getElementById(`block_${model.Goalcode}`).style.display = "none";
                // 找到对应的goal的定位
                // 检查这里定义的goal
                console.log("检查对应的数据部分")
                console.log(model)
                this.showunfoldmap[model.Goalcode].showcherry=false
                this.showunfoldmap[model.Goalcode].showapple=true
            }
            
        }else{
            // event.target.innerHTML="-"
    
            // thisele.innerHTML = Cherryicon
           
            //  document.getElementById(`block_${model.Projectcode}`).style.display = "";

          if (texttype=="project"){
                document.getElementById(`block_${model.Projectcode}`).style.display = "";
                this.showunfoldmap[model.Projectcode].showcherry=true
                this.showunfoldmap[model.Projectcode].showapple=false
            }
         if (texttype=="goal"){
                document.getElementById(`block_${model.Goalcode}`).style.display = "";
                console.log(model)
                this.showunfoldmap[model.Goalcode].showcherry=true
                this.showunfoldmap[model.Goalcode].showapple=false
            }

        }
       

     }


     function createproject(goal,type,event){
      console.log(goal,type,event)
      // alert(this.showaddprojectzone )
      this.showaddprojectzone = true
    //   document.getElementById("createprojectzone").style.display="block"
      
    //   document.getElementById("addtaskinprojectzone").style.opacity=0.1
      document.getElementById("goaltreedatapart").style.opacity=0.1
       document.getElementById("goaltreedatapart").style.pointerEvents="none"
      document.getElementById("top").style.opacity=0.1
      
      this.passedgoal={"goalcode":goal.Goalcode,"goaltext":goal.Name}
    //   document.getElementById("createprojectzone").style.opacity=1.0
     }


    


      function createtaskinproject(goal,project,event){
      console.log(goal,project,event)
        // alert("在项目中添加任务")
        

    //   document.getElementById("createprojectzone").style.opacity=0.1
      document.getElementById("goaltreedatapart").style.opacity=0.1
       document.getElementById("goaltreedatapart").style.pointerEvents="none"
      document.getElementById("top").style.opacity=0.1
    //   this.passedgoal={"goalcode":goal.Goalcode,"goaltext":goal.Name}
    
        this.showaddtaskbox= true
        this.selectedgoal = goal
        this.selectedproject = project
     }

    function  giveupproject(goal,project,event){
      console.log(goal,project,event)
        var smartmode = false
     if(smartmode){
      console.log(goal,project,event)
      if(project.Alltasksinproject!=undefined&project.Alltasksinproject!=null){
          alert("还有子任务请先完成")
        }
     }
     



        console.log("check")
        console.log(project)
           var env =  configure_options["env"]
       
        console.log(configure_options["app_setting"][env])
        var serverhost =configure_options["app_setting"][env]["serverhost"]
            var postbody={
            "goalcode":goal.Goalcode,
            "projectname":project.Name,
            "projectcode":project.Projectcode,
            "projectstatus":"giveup"
            }
   axios.post(serverhost +'/v1/updateproject', postbody)
    .then(response => {
        console.log(response.data)
        // emitter.emit("refreshtasks","today")
        // emitter.emit("refresh_review","from_taskrecursion_component")
        this.get_goaltree()
    })
    .catch(error=>{
        
        if (error.response.status==908){
            
            notification.error({
  message: '错误通知',
  description: '项目还有没完成或放弃的子任务🚨。',
  style: {
    fontFamily: 'KaiTi, sans-serif',
    fontSize: '16px',
    color: 'red', // 字体颜色
  },
});

        }else{
          console.log(error)
        alert("标记放弃项目时出错")
        }
        
     
      })



      
    }

   function  finishproject(goal,project,event){
     var smartmode = false
     if(smartmode){
      console.log(goal,project,event)
      if(project.Alltasksinproject!=undefined&project.Alltasksinproject!=null){
          alert("还有子任务请先完成")
        }
     }
     
    console.log("check")
    console.log(project)
    var env =  configure_options["env"]
    
    console.log(configure_options["app_setting"][env])
    var serverhost =configure_options["app_setting"][env]["serverhost"]
        var postbody={
        "goalcode":goal.Goalcode,
        "projectname":project.Name,
        "projectcode":project.Projectcode,
        "projectstatus":"finished"
        }
    axios.post(serverhost +'/v1/updateproject', postbody)
      .then(response => {
          console.log(response.data)
          // emitter.emit("refreshtasks","today")
          // emitter.emit("refresh_review","from_taskrecursion_component")
          this.get_goaltree()
      })
      .catch(error=>{
        
        if (error.response.status==908){
     
            notification.error({
  message: '错误通知',
  description: '项目还有没完成或放弃的子任务🚨。',
  style: {
    fontFamily: 'KaiTi, sans-serif',
    fontSize: '16px',
    color: 'red', // 字体颜色
  },
});
        }else{
          console.log(error)
        alert("标记完成项目时出错")
        }
        
     
      })
      
        
    }


   function get_goaltree(){

var _this = this
_this.showloading = true;
// _this.todaytabstatus = "tabactive"
// _this.tomorrowtabstatus = "no"
var env =  configure_options["env"]

 console.log(configure_options["app_setting"][env])
 var serverhost =configure_options["app_setting"][env]["serverhost"]

axios.get( serverhost + "/v1/goalsgraph").then(
response=>{

var data = response.data

 // goaltreedata = data
//  console.log(data)

// // 尝试处理一下这个数据
var AllSearcheditems=[]
// var icount;
var allusertags = []


var userlevel = data.userlevel
var showalias =data.showgoalalias
if(showalias == "yes"){
 this.showgoalalias = true
}else{
this.showgoalalias = false
}

if(userlevel!="high"){
 _this.showmapofgoal = false
}else{
 _this.showmapofgoal = true
}
// 顺便做一下缩近处理
for(var i=0;i<data.goals.length;i++){
 var thisgoal = data.goals[i];
 var thisgoalname = data.goals[i].Name;
//  console.log("goal name is ")
//  console.log(thisgoalname)
 // if(thisgoalname=="zero"){
 //   thisgoalname = "Inbox"
 //   // alert(1)
 // }
 _this.showunfoldmap[thisgoal.Goalcode]={}
 _this.showunfoldmap[thisgoal.Goalcode].showcherry = true
 _this.showunfoldmap[thisgoal.Goalcode].showapple = false
 AllSearcheditems.push({ value:thisgoalname,type:"goal",id:thisgoal.Goalcode})
 for(var j=0;j<thisgoal.Allprojectsingoal.length;j++){
   var thisproject = thisgoal.Allprojectsingoal[j]
   var thisprojectname = thisgoal.Allprojectsingoal[j].Name
   _this.showunfoldmap[thisproject.Projectcode]={}
 _this.showunfoldmap[thisproject.Projectcode].showcherry = true
 _this.showunfoldmap[thisproject.Projectcode].showapple = false


  
   AllSearcheditems.push({ value:thisprojectname,type:"project",id:thisproject.Projectcode})
   if(thisproject.Alltasksinproject!=null){
     for(var k=0;k<thisproject.Alltasksinproject.length;k++){
     var thistask = thisproject.Alltasksinproject[k]
     // icount= icount + 1
 
     
     AllSearcheditems.push({ value: thistask.task,id:thistask.ID,type:"task"})
    //  console.log(thistask.tasktags)
     try{
       var tags =   JSON.parse(thistask.tasktags)
     var tagsfromfilter =   Object.keys(tags).filter((key) => tags[key] === "yes");
     tagsfromfilter.push("tasktext")

     for(var l=0;l<tagsfromfilter.length;l++){

       if(tagsfromfilter[l]!="tasktext"){
         const index = allusertags.indexOf(tagsfromfilter[l]);

       if (index == -1) {

         allusertags.push(tagsfromfilter[l])
         // console.log("The value 5 is not in the array.");
         AllSearcheditems.push({ value:tagsfromfilter[l],id:"tagno",type:"tag"})
       }
       }
    
    
         

       
     }
     data.goals[i].Allprojectsingoal[j].Alltasksinproject[k].tagsarray =  tagsfromfilter;


     }catch{
       console.log("标签数据不完整")
     }
      
     
   }
   }
  
 }
}

//可以再嵌套一层axios请求来获取标签
console.log("检测数据是否有变化")
console.log(data)
_this.goaltreedata = data

_this.showloading = false;
emitter.emit("refresh_searchcontent",AllSearcheditems)
}
).catch(error=>{console.log(error)})
}

      return{
      overelement:ref(null),
      dropinproject,
      showloading:true,
      goaltreedata:ref({}),
      showunfoldmap:ref({}),
      action:ref("edittask"),
      showmapofgoal:ref(false),
      dragabledata:ref(true),
       createproject,
      
       addTaskInProjectEmi,
       giveup,
       get_goaltree,
       turnoff_addtaskinprojectzone_func,
       turnoff_addprojectzone_func,
       createtaskinproject,
       schedule2today,
       schedule2tomorrow,
       toggle,
       giveupproject,
       finishproject,
       selectedproject:ref({}),
       selectedgoal:ref({}),
       currenttask:ref({}),
       showaddtaskbox:ref(false),
       showedittaskbox:ref(false),
       showaddprojectzone:ref(false),
       showgoalalias:ref(false),
    //    get_goaltree,
       passedgoal:ref({}),
       updateTaskProject,
       showaddprojectinright:ref(false),
       teststring:ref("helloworld"),
       taskchecked: ref(false),
       drag,
        allowDrop,
        drop,
        dragenterfunc,
        dragleavefunc,
      }
       
    
}

},



)
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">




#createprojectzone{
    display: block;

}

#goalrecursiontree{
    text-align: left;
    /* position:absolute; */
    /* font-family: fangsong; */
    /* color: brown; */
}
.project{
    position: relative;
    left:10px;
    /* top:20px; */
}
.task{
    position: relative;
    /* top:20px; */
    left:10px;
}
.goal{
    position: relative;
    left:10px;
  
}

a{
    text-decoration: none;
    text-decoration-color: none;
    /* color: brown; */
}
a.goaltext{
    color: red;
    font-weight: 900;
    /* font-size:20px; */
}

a.projecttext{
    color:rgb(47, 20, 218);
    font-weight: 600;
    
}

button.todaybutton{
    border-style: none;
}

button.todaybutton:hover{
    border-style: none;
    background: orangered;
}
button.tomorrowbutton{
    border-style: none;
}

button.tomorrowbutton:hover{
    border-style: none;
    background: yellow;
}

.tasktext_block{
   display:flex;
    max-width: 100%;
    margin-right: 100px;
    overflow: auto;
    border-radius: 3px;
    border: 1px solid #b6b3b3;
    width: 40vw;
    
    margin:1px;
    justify-content: space-between;
    &:hover{
        background:rgb(247, 243, 243);
      }

    .tasktext{
    /* color:black; */
    color:#333;
    /* font-size: 13px; */
    max-width: 37vw;
    margin:1px;
    padding:0.5px;
    
       line-height: 1.75;

      

}

.hardtag{

background: rgb(206, 16, 41);
color: white;
font-size:larger;
font-family: goodfont;

}



}

.togglebutton{
    border-style: none;
    background:none;

}
/* .togglebutton:hover{
    border-style: none;
    background: yellow;
} */

.addprojectbutton{
    border-style: none;
    background: none;
}
.addprojectbutton:hover{
    border-style: none;
    background:burlywood;
}

.addtaskbutton{
    border-style: none;
    /* background:burlywood; */
}






.buttonzone_in_project{
    display:flex;
    width: 100px;
    /* margin-top:2px; */
    justify-content: space-evenly;
    flex-direction:row;
}

.buttonzone_in_task{
    display:flex;
    margin-top:4px;
    width: 60px;
    justify-content: space-evenly;
    flex-direction:row;
}


.giveupbutton{
    border-style: none;
    /* background:burlywood; */
}

.giveupbutton:hover{
    border-style: none;
    background: red;
    /* background:burlywood; */
}


.addtaskbutton:hover{
    border-style: none;
    background:coral;
}

#createprojectzone{
    position: fixed;
    top: 30%;
    left:50%;
    width: 30%;
    /* height: 300px; */
    margin-left: 100px;
    opacity: 1;
    /* left:10%;2 */
    /* pointer-events: none; */
    background:khaki;
}



#addtaskinprojectzone{
    position: fixed;
    top: 30%;
    left:50%;
    width: 30%;
    /* height: 300px; */
    margin-left: 100px;
    opacity: 1;
    /* left:10%;2 */
    /* pointer-events: none; */
    /* background:khaki; */
}

.projectshowzone_right{
    display:flex;
    flex-direction: row;
    
}

.projectnameinaddzone{
    color: red;
}


.buttoningoal{
  display: flex;
  width:50px;
  /* margin-left:5px; */
  justify-content:space-evenly;
}

.goaltitlezone{
  display: flex;
  width:40vw;

}

#goalrecursiontree{
  margin-bottom: 10px;
}


</style>
